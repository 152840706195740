<template>
  <div>

    <div class="ps-shopping">
      <h3 class="ps-shopping__title">Sifarişlər </h3>

      <div class="my-4">

        <table class="table table-bordered">
          <thead>
          <tr>
            <th>ID</th>
            <th>Qiymət</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>

          <tr v-if="orders.length<1">
            <td colspan="4">
              <div class="alert alert-info">
                Sizin sifarişiniz yoxdur
              </div>
            </td>
          </tr>
          <tr v-for="o in orders" :key="o.id">
            <td>
              <router-link :to="`/profile/order/${o.id}`">#{{ o.id }}</router-link>
            </td>
            <td>
              {{ o.price }} AZN
            </td>
            <td>
              <div class="badge bg-info text-white" v-if="o.orderStatus==='new'">
                Yeni sifariş
              </div>
              <div class="badge bg-success text-white" v-else-if="o.orderStatus==='confirmed'">
                Təsdiqlənmiş sifariş
              </div>
              <div class="badge bg-warning"
                   v-else-if="o.orderStatus==='waiting_payment'">
                Ödəniş tələb olunur
              </div>
              <div class="badge bg-success text-white" v-else-if="o.orderStatus==='paid'">
                Ödəniş qəbul edilib
              </div>
              <div class="badge bg-success text-white" v-else-if="o.orderStatus==='preparing'">
                Paketiniz hazırlanır
              </div>
              <div class="badge bg-success text-white" v-else-if="o.orderStatus==='delivering'">
                Paketiniz yoldadır
              </div>
              <div class="badge bg-success text-white" v-else-if="o.orderStatus==='finished'">
                Sifarişiniz tamalandı
              </div>
              <div class="badge bg-danger text-white" v-else-if="o.orderStatus==='cancelled'">
                Sifariş ləğv edildi
              </div>
              <div class="abadge bg-success text-white" v-else>
                {{ o.orderStatus }}
              </div>
            </td>
            <td>
              <router-link class="text-primary" :to="`/profile/order/${o.id}`">ətraflı</router-link>
            </td>
          </tr>

          </tbody>
        </table>

      </div>

    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {baseUrl} from '../../repositories/Repository';
import {getOrders} from "../../repositories/UserRepository";

export default {
  layout: 'default',
  data() {
    return {
      baseDomain: baseUrl,
      orders: [],
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      token: state => state.auth.token,
    }),
  },
  async created() {

    getOrders(this.token).then((orders) => {
      this.orders = orders;
    })

    this.$store.commit('app/setLoading', false);
  },
  methods: {}
}
</script>
