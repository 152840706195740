<template>
    <form @submit.prevent="addAddress()">

        <div class="mb-3">
            <label>Şəhər *</label>
            <select class="form-control" v-model="city" required>
                <option v-for="c in cityList" :key="c.id" :value="c.id">{{c.name}}</option>
            </select>
        </div>
        <div class="mb-3" v-if="city>0">
            <label>İstiqamət *</label>
            <select class="form-control" v-model="district" required>
                <option v-for="d in districtList" :key="d.id" :value="d.id">{{d.name}}</option>
            </select>
        </div>
        <div class="mb-3">
            <label>Ünvan *</label>
            <input class="form-control" v-model="address" required>
        </div>
<!--        <div class="mb-3">
            <label>Poçt indeksi</label>
            <input class="form-control" v-model="zip" placeholder="AZ1000">
        </div>-->
        <div class="mb-3">
            <button class="btn btn-success btn-lg">Əlavə et</button>
        </div>
    </form>
</template>

<script>
    import {mapState} from 'vuex';
    import {addNewAddress, getCityList, getDistrictList} from "../../repositories/UserRepository";

    export default {
        data() {
            return {
                cityList: [],
                districtList: [],
                city: 0,
                district: 0,
                name: '',
                address: '',
                zip: '',
                isMain: false
            };
        },
        computed: {
            ...mapState({
                loading: state => state.app.loading,
                user: state => state.auth.user,
                token: state => state.auth.token,
            }),
        },
        watch: {
            city(newValue) {
                if (newValue > 0) {
                    getDistrictList(newValue).then(data => {
                        this.districtList = data;
                    })
                }
            }
        },
        methods: {
            addAddress() {

                addNewAddress(this.token, this.district, this.address, this.address, this.zip, 0).then(data => {
                    if (data.code === 200) {
                        this.$emit('addressCreated', data.id)

                        this.city=0;
                        this.district=0;
                        this.name='';
                        this.address='';
                        this.zip='';

                    } else {
                        alert("Error " + data.code + "; " + data.message);
                    }
                });

            },
        },
        emits: ['addressCreated'],
        created() {
            getCityList().then(data => {
                this.cityList = data;
            })
        }
    }
</script>
