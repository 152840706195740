<template>
  <div class="ps-product ps-product--standard" v-if="product">
    <div class="ps-product__thumbnail">
      <router-link class="ps-product__image" :to="`/product/${product.slug}`" v-if="product.thumbnail">
        <figure>
          <img :src="imagePath + product.thumbnail.url" :alt="product.name"/>
          <img v-if="product.thumbnail_back" :src="imagePath + product.thumbnail_back.url"
               :alt="product.name"/>
        </figure>
      </router-link>
      <div class="ps-product__actions">
                        <div class="ps-product__item" data-toggle="tooltip" data-placement="left" title="Seçilmişlər">
                            <a href="#" @click.prevent="removeProductFromWishlist()" v-if="product.isFavorite">
                                <i class="fa fa-heart"></i>
                            </a>
                            <a href="#" @click.prevent="addProductToWishlist()" v-else><i class="fa fa-heart-o"></i></a>
                        </div>
<!--        <div class="ps-product__item" data-toggle="tooltip" data-placement="left" title="Sürətli baxış">
          <a href="#" @click.prevent="productQuickview()"><i class="fa fa-search"></i></a>
        </div>
        <div v-if="product.is_stock" class="ps-product__item" data-toggle="tooltip" data-placement="left"
             title="Səbətə əlavə et">
          <a href="#" @click.prevent="addToCart()"><i class="fa fa-shopping-basket"></i></a>
        </div>-->
      </div>
      <div class="ps-product__badge pl-2 pt-2" v-if="product.badges.length">
        <div
            v-for="item in product.badges"
            :key="item.id"
            :class="[
                        'ps-badge', 'mr-2', 'd-inline-block',
                        item.value === 'season'
                            ? 'ps-badge--season'
                            : item.value === 'new'
                            ? 'ps-badge--new'
                            : item.value === 'sale'
                            ? 'ps-badge--sale'
                            : item.value === 'campaign'
                            ? 'ps-badge--campaign'
                            : 'ps-badge--sold'
                    ]"
        >
          {{ item.name }}
        </div>
      </div>

    </div>
    <div class="ps-product__content">
      <!--            <router-link class="ps-product__branch" :to="`/category/${product.category.slug}`">
                      {{product.category.name}}
                  </router-link>-->
      <div class="ps-product__rating">

        <div class="d-inline-block">

          <i v-if="product.rating < 2" class="fa mr-1 text-warning fa-star-o"></i>
          <i v-else-if="product.rating < 4" class="fa mr-1 text-warning fa-star-half"></i>
          <i v-else class="fa mr-1 text-warning fa-star"></i>
          {{ parseFloat(product.rating).toFixed(1) }}
        </div>

        <span class="ps-product__review">({{ product.rating_count }})</span>
      </div>

      <h5 class="ps-product__title">
        <router-link :to="`/product/${product.slug}`">{{ product.name }}</router-link>
      </h5>
      <div class="ps-product__meta">
        <template v-if="product.percent>0">
              <span class="ps-product__del" v-if="product.sale_price">
                {{ product.price }} AZN
                </span>
          <div class="badge badge-success ml-3" v-if="product.percent>0">
            -{{ product.percent }}%
          </div>
          <br>
        </template>
        <div v-else>&nbsp;</div>
        <span :class="[ 'ps-product__price', product.sale_price ? 'sale' : '' ]">
                    {{ product.sale_price ? product.sale_price : product.price }} AZN
                </span>

      </div>


      <!--
      <div class="ps-product__footer" v-if="product.footer">
          No of pcs <br/>available <span>{{ product.available }}</span>
      </div>-->
      <!--            <div class="ps-product__desc" v-if="product.features !==''">
                      <ul class="ps-product__list">
                          <li v-for="f in product.features.split('\n')" :key="f">{{f}}</li>
                      </ul>
                  </div>-->
      <div class="ps-product__actions ps-product__group-mobile pb-0">
        <div class="ps-product__quantity">
          <div class="def-number-input number-input safari_only">
            <button
                class="minus"
                @click="quantity > 0 ? quantity-- : quantity"
            >
              <i class="icon-minus"></i>
            </button>
            <input
                class="quantity"
                min="0"
                v-model="quantity"
                type="number"
                style="border: none"
            />
            <button class="plus" @click="quantity++">
              <i class="icon-plus"></i>
            </button>
          </div>
        </div>
        <div class="ps-product__cart">
          <a class="ps-btn ps-btn--warning"
             href="#"
             @click.prevent="addToCart()">Səbətə əlavə et</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {baseUrl, imagePath} from "../../repositories/Repository";
import {mapState} from "vuex";

export default {
  props: {
    product: {
      type: Object
    }
  },
  components: {},
  data() {
    return {
      baseDomain: baseUrl,
      imagePath: imagePath,
      quantity: 1
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      token: state => state.auth.token,
    }),

    productPrice() {
      if (this.product) {
        return this.product.sale_price
            ? this.product.sale_price
            : this.product.price;
      } else {
        return 0;
      }
    },
    totalPrice() {
      return Math.round(this.quantity * this.productPrice );
    },
    rating() {
      return Math.floor(Math.random() * Math.floor(6) + 3);
    }
  },
  methods: {
    addToCart() {
      const params = {
        productId: this.product.id,
        optionId: null,
        count: this.quantity
      };
      this.$store.dispatch('cart/addProductToCart', params);
      this.$store.commit('cart/setDialogAddCart', true);
      this.$store.commit('cart/setProductOverview', this.product);
      this.$store.commit('cart/setDialogQuickview', false);
      this.$store.commit('product/addCartProduct', this.product);
      this.$store.commit('cart/setDialogAddCart2', false);
    },
    productQuickview() {
      this.$store.commit('cart/setProductOverview', this.product);
      this.$store.commit('cart/setDialogQuickview', true);
      this.$store.commit('cart/setDialogAddCart', false);
      this.$store.commit('cart/setDialogAddCart2', false);
    },
    addProductToWishlist() {

      if(!this.user){
        const message = {
          icon: 'fa fa-user',
          message: 'Bu əməliyyatı yerinə yetirmək üçün daxil olmalısınız!'
        };
        this.$store.commit('app/setMessageNotify', message);
        this.$store.commit('app/setDialogNotify', true);
        return;
      }

      const params = {
        id: this.product.id
      };
      const title = this.product ? this.product.name : '';
      const message = {
        icon: 'icon-star',
        message: title + ' seçilmişlərə əlavə edildi!'
      };
      this.$store.dispatch('wishlist/addItemToWishlist', params);
      this.$store.commit('cart/setDialogAddCart2', false);
      this.$store.commit('app/setMessageNotify', message);
      this.$store.commit('app/setDialogNotify', true);
    },
    removeProductFromWishlist() {
      if(!this.user){
        const message = {
          icon: 'fa fa-user',
          message: 'Bu əməliyyatı yerinə yetirmək üçün daxil olmalısınız!'
        };
        this.$store.commit('app/setMessageNotify', message);
        this.$store.commit('app/setDialogNotify', true);
        return;
      }
      const params = {
        id: this.product.id
      };
      this.$store.dispatch('wishlist/removeItemFromWishlist', params);
    }
  }
};
</script>
