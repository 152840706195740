<template>
  <div class="ps-product__info">
    <div class="ps-product__badge"><span class="ps-badge ps-badge--instock" v-if="product.is_stock">
            ANBARDA VAR
        </span>
      <span class="ps-badge ps-badge--outstock" v-else>
                Anbarda yoxdur
            </span></div>
    <div class="ps-product__branch"><a>{{ product.category.name }}</a></div>
    <div class="ps-product__title">
      <a>{{ product.name }}</a>
    </div>

    <div class="ps-product__rating">
      <div class="d-inline-block">
        <i v-for="i in 5" :key="i" class="fa mr-1 text-warning"
           :class="{'fa-star': product.rating>i-1, 'fa-star-o': product.rating<i}"></i>
        {{ parseFloat(product.rating).toFixed(1) }}
      </div>

      <span class="ps-product__review">( {{ product.rating_count }} Reviews)</span>
    </div>

    <div class="ps-product__desc" v-if="product.features">
      <ul class="ps-product__list">
        <li class="d-block" v-for="f in product.features.split('\n')" :key="f">{{ f }}</li>
      </ul>
    </div>

    <div class="ps-product__type">
      <ul class="ps-product__list">
        <li><span class="ps-list__title">SKU: </span><a class="ps-list__text">{{ product.sku }}</a></li>
      </ul>
    </div>


    <p class="ps-desc" v-html="productDescription"></p>


    <div class="ps-product__meta">
      <div>
        <span :class="[ 'ps-product__price', product.sale_price ? 'sale' : '' ]">
            {{ product.sale_price ? product.sale_price : product.price }} AZN
        </span>
        <span class="ps-product__del" v-if="product.sale_price"> {{ product.price }} AZN</span>
      </div>
    </div>

    <div class="ps-product__quantity" v-if="product.is_stock">
      <h6> Miqdarı</h6>
      <div class="d-lg-flex align-items-center" >
        <div class="def-number-input number-input safari_only mb-1">
          <button class="minus" @click="quantity > 0 ? quantity-- : quantity"><i class="icon-minus"></i>
          </button>
          <input style="border: none" class="quantity" min="0" v-model="quantity" type="number"/>
          <button class="plus" @click="quantity++"><i class="icon-plus"></i></button>
        </div>
        <a class="ps-btn ps-btn--warning ml-0 mb-1" href="#" @click.prevent="addToCart()">Səbətə əlavə et</a>
        <a :href="whatsappLink" target="_blank" class="ps-btn ps-btn--sm ps-btn--outline ml-2 mb-1 one-click-order">
          <i class="fa fa-hand-pointer-o"></i>
          Bir klikdə al
        </a>
      </div>

    </div>
    <!--        <div class="ps-product__variations">
                <a class="ps-product__link" href="#" @click.prevent="removeProductFromWishlist()" v-if="isWishlist">Seçilmişlərdən sil</a>
                <a class="ps-product__link" href="#" @click.prevent="addProductToWishlist()" v-else>Seçilmişlərə əlavə et</a>
            </div>-->

    <div class="ps-product__social" v-if="design && design.social">
      <social-icon-color/>
    </div>
  </div>
</template>
<script>     import {baseUrl} from "../../repositories/Repository";
import SocialIconColor from "../layout/SocialIconColor";
import {mapState} from 'vuex';

export default {
  props: {
    product: {type: Object}, design: {
      type: Object, default: () => {
      }
    }
  }, components: {SocialIconColor}, data() {
    return {baseDomain: baseUrl, quantity: 1, selectFeature: []};
  }, computed: {
    ...mapState({
      wishlist: state => state.wishlist.items,
      settings: state => state.app.settings,
    }),
    whatsappLink() {
      return `https://wa.me/${this.settings.whatsappNumber}?text=Salam! ${this.product.name} məhsulunu sifariş etmək istəyirəm. https://bouquet.az/product/${this.product.slug}`;
    },
    isWishlist() {
      const item = this.wishlist.find(item => parseInt(item) === this.product.id);
      if (item) {
        return true;
      }
      return false;
    }, rating() {
      return Math.floor(Math.random() * Math.floor(6) + 3);
    },
    productDescription() {
      if (this.product.description == null) {
        return '';
      } else {
        return this.nl2br(this.product.description, false);
      }
    }
  }, methods: {
    nl2br(str, is_xhtml) {
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },
    handleSelectFeature(text, value) {
      if (this.selectFeature.length) {
        let check = false;
        this.selectFeature.forEach(element => {
          if (element.text == text) {
            element.value = value;
            check = true;
          }
        });
        if (!check) {
          const data = {text: text, value: value};
          this.selectFeature.push(data);
        }
      } else {
        const data = {text: text, value: value};
        this.selectFeature.push(data);
      }
    }, checkActive(text, value) {
      if (this.selectFeature.length) {
        let check = false;
        this.selectFeature.forEach(element => {
          if (element.text == text && element.value == value) {
            check = true;
          }
        });
        return check;
      }
      return false;
    }, addProductToWishlist() {
      if(!this.user){
        const message = {
          icon: 'fa fa-user',
          message: 'Bu əməliyyatı yerinə yetirmək üçün daxil olmalısınız!'
        };
        this.$store.commit('app/setMessageNotify', message);
        this.$store.commit('app/setDialogNotify', true);
        return;
      }

      const params = {id: this.product.id};
      const title = this.product ? this.product.name : '';
      const message = {icon: 'icon-shield-check', title: title, message: title + ' seçilmişlərə əlavə edildi!'};
      this.$store.dispatch('wishlist/addItemToWishlist', params);
      this.$store.commit('product/addWishlistItem', this.product);
      this.$store.commit('cart/setDialogAddCart2', false);
      this.$store.commit('app/setMessageNotify', message);
      this.$store.commit('app/setDialogNotify', true);
    }, removeProductFromWishlist() {
      if(!this.user){
        const message = {
          icon: 'fa fa-user',
          message: 'Bu əməliyyatı yerinə yetirmək üçün daxil olmalısınız!'
        };
        this.$store.commit('app/setMessageNotify', message);
        this.$store.commit('app/setDialogNotify', true);
        return;
      }
      const params = {id: this.product.id};
      this.$store.dispatch('wishlist/removeItemFromWishlist', params);
    }, addToCart() {
      const params = {
        productId: this.product.id,
        optionId: null,
        count: this.quantity
      };
      this.$store.commit('cart/setDialogQuickview', false);
      this.$store.dispatch('cart/addProductToCart', params);
      this.$store.commit('cart/setDialogAddCart', true);
      this.$store.commit('cart/setDialogAddCart2', false);
      this.$store.commit('cart/setProductOverview', this.product);
      this.$store.commit('product/addCartProduct', this.product);

    }
  }
}; </script>

<style lang="scss" scoped>

.one-click-order {
  border-color: #1b1e21 !important;
  color: #1b1e21 !important;
  white-space: nowrap;
  width: auto !important;

  &:hover {
    background-color: #1b1e21 !important;
    color: #fff !important;
  }

}

</style>
