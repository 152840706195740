<template>
    <div style="padding-top: 100px;">
        <div class="ps-contact">
            <div class="container">
                <breadcrumb :dataList="breadcrumb"/>
                <div class="ps-contact__content" v-if="user!==null">
                    <div class="row">
                        <div class="col-12 ">

                            <router-view></router-view>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import Breadcrumb from "../layout/page/Breadcrumb";
    import {mapState} from "vuex";

    export default {
        name: "Profile",
        components: {Breadcrumb},
        computed: {
            ...mapState(
                {user: state => state.auth.user}
            ),
            breadcrumb() {
                return [
                    {
                        url: '/',
                        text: 'Əsas səhifə'
                    },
                    {
                        url: '/profile',
                        text: 'Profil'
                    },
                ];
            },

        },
        watch: {
            user(newData) {
                console.log(newData);
                if (newData === null || newData.length === 0) {
                    window.location.href = '/login';
                }
            }
        },


      methods: {
        logoutUser() {
          this.$store.dispatch('auth/logout');
        }
      },
    }
</script>

<style scoped>

</style>
