<template>
  <div style="padding-top: 100px;">
    <div class="ps-account">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="ps-form--review">
              <h2 class="ps-form__title">Qeydiyyat</h2>
              <div class="ps-form__group">
                <label for="registerName" class="ps-form__label">Adınız *</label>
                <input
                    class="form-control ps-form__input p-3"
                    type="text"
                    id="registerName"
                    v-model="registerName"
                    required
                    @input="v$.registerName.$touch()"
                    @blur="v$.registerName.$touch()"
                />
                <div class="text-danger" v-for="e in registerNameErrors" :key="e">{{ e }}</div>
              </div>

              <div class="ps-form__group">
                <label for="registerEmail" class="ps-form__label">E-mail *</label>
                <input
                    class="form-control ps-form__input p-3"
                    type="email"
                    id="registerEmail"
                    v-model="registerEmail"
                    required
                    @input="v$.registerEmail.$touch()"
                    @blur="v$.registerEmail.$touch()"
                />
                <div class="text-danger" v-for="e in registerEmailErrors" :key="e">{{ e }}</div>
              </div>
              <div class="ps-form__group">
                <label for="registerPhone" class="ps-form__label">Telefon *</label>
                <input
                    class="form-control ps-form__input p-3"
                    type="tel"
                    id="registerPhone"
                    v-model="registerPhone"
                    required
                    @input="v$.registerPhone.$touch()"
                    @blur="v$.registerPhone.$touch()"
                />
                <div class="text-danger" v-for="e in registerPhoneErrors" :key="e">{{ e }}</div>
              </div>
              <div class="ps-form__group">
                <label for="registerPassword" class="ps-form__label">Şifrə *</label>
                <div class="input-group">
                  <input
                      class="form-control ps-form__input p-3"
                      v-model="registerPassword"
                      id="registerPassword"
                      :type="show1 ? 'text' : 'password'"
                      required
                      @input="v$.registerPassword.$touch()"
                      @blur="v$.registerPassword.$touch()"
                  />
                  <div class="input-group-append">
                    <a class="input-group-text" href="#" @click.prevent="show1 = !show1">
                      <i class="fa fa-eye fa-2x"></i>
                    </a>
                  </div>
                </div>

                <div class="text-danger" v-for="e in registerPasswordErrors" :key="e">{{ e }}</div>
              </div>

              <vue-turnstile :key="turnstileControl" site-key="0x4AAAAAAA7gyNJC5pNcsVAi" v-model="captcha" />

              <p v-if="registerError != null" class="alert alert-danger">
                {{ registerError }}
              </p>
              <div class="ps-form__submit">
                <button class="ps-btn ps-btn--warning mr-4" @click="handleLogin()">
                  Qeydiyyat
                </button>
              </div>
              <router-link class="ps-account__link" to="/login">
                Giriş
              </router-link>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from '@vuelidate/core';
import {required, email, integer} from '@vuelidate/validators';
import {mapState} from "vuex";
import VueTurnstile from 'vue-turnstile';

export default {
  validations: {
    registerName: {required},
    registerPhone: {required, integer},
    registerEmail: {required, email},
    registerPassword: {required}
  },
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      show1: false,
      registerName: null,
      registerPhone: null,
      registerEmail: null,
      registerPassword: null,
      captcha: null,
      turnstileControl: 1
    };
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      registerError: state => state.auth.registerError
    }),
    registerNameErrors() {
      const errors = [];
      this.v$.$errors.forEach(function (error) {
            if (error.$property === "registerName") {
              if (error.$validator === 'required') {
                errors.push('Bu bölmə vacibdir');
              }
            }
          }
      )
      return errors;
    },
    registerPhoneErrors() {
      const errors = [];
      this.v$.$errors.forEach(function (error) {
            if (error.$property === "registerPhone") {
              if (error.$validator === 'required') {
                errors.push('Bu bölmə vacibdir');
              }
              if (error.$validator === 'integer') {
                errors.push('Ancaq rəqəm daxil edin');
              }
            }
          }
      )
      return errors;
    },
    registerEmailErrors() {
      const errors = [];
      this.v$.$errors.forEach(function (error) {
            if (error.$property === "registerEmail") {
              if (error.$validator === 'email') {
                errors.push('E-mailin formatı doğru deyil');
              }
              if (error.$validator === 'required') {
                errors.push('E-mail vacib bölmədir');
              }
            }
          }
      )
      return errors;
    },
    registerPasswordErrors() {
      const errors = [];
      this.v$.$errors.forEach(function (error) {
            if (error.$property === "registerPassword") {
              if (error.$validator === 'required') {
                errors.push('Şifrə vacib bölmədir');
              }
            }
          }
      )
      return errors;
    }
    ,
  },

  watch: {
    user(newValue) {
      if (newValue != null) {
        let l = localStorage.getItem("goBackAfterLogin");
        localStorage.removeItem("goBackAfterLogin")
        if (!l) {
          l = '/profile';
        }
        window.location.href = l;
      }
    }
  },
  methods: {
    handleLogin() {
      this.v$.registerName.$touch();
      this.v$.registerPhone.$touch();
      this.v$.registerEmail.$touch();
      this.v$.registerPassword.$touch();
      if (!this.v$.$invalid) {

        let name = this.registerName;
        let surname = '';

        if (this.registerName.indexOf(' ') > -1) {
          let p = name.split(' ');
          name = p[0];
          surname = p[1];
        }

        let params = {
          name: name, surname: surname,
          birthday: '', gender: 'm', phone: this.registerPhone,
          email: this.registerEmail, password: this.registerPassword,
          captcha: this.captcha
        };

        this.$store.commit('app/setLoading', true);
        this.$store.dispatch('auth/register', params);
        this.$store.commit('app/setLoading', false);
        setTimeout(() => {
          this.turnstileControl = this.turnstileControl + 1;
        }, 2000);
      }
    }
  },
  components: {
    VueTurnstile
  }
}
;
</script>
