<template>
  <div style="padding-top: 100px;">
    <div class="ps-shopping">
      <div class="container">
        <breadcrumb :dataList="breadcrumb"/>
        <h3 class="ps-shopping__title">Səbət<sup>({{ productCartQuantity }})</sup></h3>
        <div class="ps-shopping__content">
          <div class="row">
            <div class="col-12 col-sm-7 col-md-9">
              <ul class="ps-shopping__list">
                <li v-for="(chartItem, index) in cartItems" :key="index">
                  <product-wishlist :product="chartItem.product" :quantity="chartItem.count"
                                    type="cart"/>
                </li>
              </ul>
              <div class="ps-shopping__table">
                <table class="table ps-table ps-table--product">
                  <thead>
                  <tr>
                    <th class="ps-product__remove"></th>
                    <th class="ps-product__thumbnail"></th>
                    <th class="ps-product__name">Məhsul</th>
                    <th class="ps-product__meta">Qiymət</th>
                    <th class="ps-product__quantity">Miqdar</th>
                    <th class="ps-product__subtotal">Cəmi</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(cartItem, index) in cartItems" :key="index">
                    <td class="ps-product__remove">
                      <a href="#" @click.prevent="handleRemove(cartItem.product)"><i
                          class="icon-cross"></i></a></td>
                    <td class="ps-product__thumbnail">
                      <router-link class="ps-product__image"
                                   :to="`/product/${cartItem.product.slug}`"
                                   v-if="cartItem.product.thumbnail">
                        <figure><img :src="baseDomain + cartItem.product.thumbnail.url" alt>
                        </figure>
                      </router-link>
                    </td>
                    <td class="ps-product__name">
                      <router-link :to="`/product/${cartItem.product.slug}`">
                        {{ cartItem.product.name }}
                      </router-link>
                    </td>
                    <td class="ps-product__meta">
                      <div v-if="cartItem.product.is_stock">
                                                <span :class="[
                                                    'ps-product__price',
                                                    cartItem.product.sale_price ? 'sale' : ''
                                                ]">
                                                    {{
                                                    cartItem.product.sale_price ? cartItem.product.sale_price : cartItem.product.price
                                                  }} AZN
                                                </span>
                        <span class="ps-product__del" v-if="cartItem.product.sale_price">
                                                    {{ cartItem.product.price }} AZN
                                                </span>
                      </div>
                      <div v-else>
                                                <span class="badge badge-danger">
                                                tükənmişdir
                                            </span>
                      </div>

                    </td>
                    <td class="ps-product__quantity" v-if="cartItem.product.is_stock">
                      <div class="def-number-input number-input safari_only">
                        <button
                            class="minus"
                            @click="handleDecreaseItem(cartItem.product, cartItem.count)"
                        >
                          <i class="icon-minus"></i>
                        </button>
                        <input style="border: none"
                               class="quantity"
                               min="0"
                               :value="cartItem.count"
                               type="number"
                               @input="val => handleChangeQuantity(val, cartItem.product)"
                        />
                        <button class="plus"
                                @click="handleIncreaseItem(cartItem.product, cartItem.count)">
                          <i class="icon-plus"></i>
                        </button>
                      </div>
                    </td>
                    <td class="ps-product__subtotal" v-if="cartItem.product.is_stock">
                      {{ productTotal(cartItem.product, cartItem.count) }} AZN
                    </td>
                  </tr>
                  <tr v-if="!cartItems || !cartItems.length">
                    <td colspan="6">Səbətiniz boşdur</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="ps-shopping__footer">
                <div class="ps-shopping__button">
                  <button v-if="cartItems.length>0" class="ps-btn ps-btn--primary" type="button"
                          @click="handleClearAll()">
                    Səbəti təmizlə
                  </button>
                  <button class="ps-btn ps-btn--primary" type="button" @click="handleUpdateCart()">
                    Səbəti yenilə
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-5 col-md-3" v-if="cartItems.length>0">
              <div class="ps-shopping__box">

                <div class="ps-shopping__row">
                  <div class="ps-shopping__label">Cəmi</div>
                  <div class="ps-shopping__price">{{ totalCart }} AZN</div>
                </div>
                <div class="ps-shopping__checkout">

                  <div v-if="user">
                    <a href="#" class="ps-btn ps-btn--warning" @click.prevent="createOrder()">
                      Sifariş ver
                    </a>
                  </div>
                  <div v-else>
                    <a href="#" class="ps-btn ps-btn--warning" @click.prevent="createOrder()">
                      Qeydiyyatdan keç
                    </a>
                    <p>
                      və ya
                    </p>
                    <a :href="whatsappLink" class="ps-btn ps-btn--success" target="_blank">
                      Qeydiyyatsız sifariş ver
                    </a>
                  </div>
                  <router-link class="ps-shopping__link" to="/products">
                    Alış-verişə davam et
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal-slot :isVisible="showOfflineOrderForm" title="Qeydiyyatsız sifariş" modalSize="lg"
                @modalClose="closeOfflineModel()">


      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="name" class="form-label">Adınız</label>
          <input type="text" class="form-control" id="name" v-model="offlineOrderForm.name" required>
        </div>

        <div class="col-md-6 mb-3">
          <label for="phone" class="form-label">Telefon</label>
          <input type="tel" class="form-control" id="phone" v-model="offlineOrderForm.phone" required>
        </div>


        <div class="col-md-12 mb-3">
          <label for="address" class="form-label">Ünvan</label>
          <input type="text" class="form-control" id="address" v-model="offlineOrderForm.address" required>
        </div>


        <div class="col-md-12 mb-3">
          <label for="note" class="form-label">Sifarişlə bağlı qeyd</label>
          <input type="text" class="form-control" id="note" v-model="offlineOrderForm.notes" required>
        </div>


        <div class="col-md-6 mb-3">
          <label for="payment" class="form-label">Ödəniş üsulu</label>
          <select class="form-control" id="payment" v-model="offlineOrderForm.payment" required>
            <option value="cash_on_door">Qapıda nağd</option>
            <option value="card_on_door">Qapıda kartla</option>
            <option value="card">Kartla onlayn</option>
          </select>
        </div>

        <div class="col-md-12 mb-3" v-if="offlineOrderForm.payment==='card'">
          <div class="alert alert-warning">
            Online ödəniş etmək üçün zəhmət olmasa qeydiyyatdan keçin və ya hesabınıza daxil olun. <br>
            <a class="text-danger" href="/login"><u>Hesabım</u></a>
          </div>
        </div>

        <div class="col-md-12 mb-3" v-else>
          <button class="ps-btn ps-btn--warning" @click.prevent="submitOfflineOrder()">Sifariş ver</button>
        </div>

      </div>

    </modal-slot>

  </div>
</template>

<script>
import Breadcrumb from "../layout/page/Breadcrumb";
import {mapState} from 'vuex';
import {baseUrl} from '@/repositories/Repository';
import ProductWishlist from "../product/ProductWishlist";
import {createOfflineOrder, createOrder} from "@/repositories/UserRepository";
import ModalSlot from "@/components/layout/ModalSlot.vue";

export default {
  layout: 'default',
  components: {ModalSlot, ProductWishlist, Breadcrumb},
  data() {
    return {
      breadcrumb: [
        {
          url: '/',
          text: 'Əsas səhifə'
        },
        {
          url: '/cart',
          extraClass: 'active',
          text: 'Səbət'
        }
      ],
      baseDomain: baseUrl,
      calculateShipping: false,

      region: null,
      country: null,
      town: null,
      postcode: null,
      couponCode: null,
      showOfflineOrderForm: false,
      offlineOrderForm: {}
    }
  },
  computed: {
    ...mapState({
      cartItems: state => state.cart.cartItems,
      user: state => state.auth.user,
      hash: state => state.auth.hash,
      token: state => state.auth.token,
      settings: state => state.app.settings,
    }),
    totalCart() {
      let total = 0;
      this.cartItems.forEach(element => {
        console.log("chart");
        console.log(element);
        let price = this.productPrice(element.product);
        total += price * element.count;
      });
      return total.toFixed(2);
    },

    whatsappLink() {

      let text = `Salam! Aşağıdakı məhsulları sifariş etmək istəyirəm:%0a \n`;

      this.cartItems.forEach(element => {
        text += `${element.product.name} - ${element.count} ədəd;%0a \n`;
      });

      return `https://wa.me/${this.settings.whatsappNumber}?text=${text}`;
    },
    productCartQuantity() {
      let quantity = 0;
      this.cartItems.forEach(element => {
        quantity += element.count;
      });
      return quantity;
    }
  },
  async mounted() {
    this.$store.commit('app/setLoading', false);
  },
  methods: {
    handleCoupon() {
      console.log('couponCode', this.couponCode);
    },
    handleUpdateCart() {

      this.$store.commit('app/setLoading', true);
      this.$store.dispatch("cart/loadCart");

      setTimeout(() => {
        this.$store.commit('app/setLoading', false);
      }, 3000);
    },
    handleClearAll() {
      this.$store.dispatch("cart/clearCart");
      this.$store.commit("product/clearAllCartProduct");
    },
    handleRemove(product) {
      this.$store.dispatch("cart/removeProductCart", product.id);
      this.$store.commit("product/removeCartProduct", product.id);
    },
    productPrice(product) {
      console.log(product);
      if (product) {
        return product.sale_price ? product.sale_price : product.price;
      } else {
        return 0;
      }
    },
    productQuantity(product) {
      const item = this.cartItems.find(item => item.id === product.id);
      if (item) {
        return item.quantity;
      }
      return 1;
    },
    productTotal(product, count) {
      let total = this.productPrice(product) * count;
      return total.toFixed(2);
    },
    handleDecreaseItem(product, count) {
      const params = {
        quantity: count - 1,
        id: product.id
      };
      this.$store.dispatch('cart/changeQuantityProductCart', params);
    },
    handleIncreaseItem(product, count) {
      const params = {
        quantity: count + 1,
        id: product.id
      };
      this.$store.dispatch('cart/changeQuantityProductCart', params);
      this.$store.commit('product/changeCartItemQuantity', params);
    },
    handleChangeQuantity(val, product) {
      if (val.data) {
        const value = parseInt(val.data);
        let params = {
          quantity: value < 0 ? 0 : value,
          id: product.id
        };

        this.$store.dispatch('cart/changeQuantityProductCart', params);
        this.$store.commit('product/changeCartItemQuantity', params);
      }
    },
    createOrder() {

      if (this.user !== null) {
        this.$store.commit('app/setLoading', true);
        createOrder(this.token, '').then((data) => {
          if (data.code === 200) {
            this.$router.push(`/profile/order/${data.order}`);
          } else {
            alert(data.message + ": " + data.error);
          }
        })
        this.$store.commit('app/setLoading', false);
      } else {
        localStorage.setItem("goBackAfterLogin", "/cart");
        this.$router.push('/login');
      }

    },
    submitOfflineOrder() {

      this.$store.commit('app/setLoading', true);
      createOfflineOrder(this.hash,
          this.offlineOrderForm.name,
          this.offlineOrderForm.phone,
          this.offlineOrderForm.address,
          this.offlineOrderForm.payment,
          this.offlineOrderForm.notes
      ).then((data) => {
        if (data.code === 200) {
          //alert order accepted
          this.closeOfflineModel();
          const message = {
            icon: 'icon-shield-check',
            title: "Uğurlu sifariş",
            message: 'Sifarişiniz üçün təşəkkür edirik. <br> Əməkdaşlarımız ən qısa zamanda sizinlə əlaqə saxlayacaqdır.',
            error: false
          };
          this.$store.commit('app/setMessageNotify', message);
          this.$store.commit('app/setDialogNotify', true);
          this.handleUpdateCart();
        } else {
          alert(data.message + ": " + data.error);
        }
      })
      this.$store.commit('app/setLoading', false);

    },
    createOfflineOrder() {
      this.showOfflineOrderForm = true;
      this.offlineOrderForm = {
        name: '',
        phone: '',
        payment: '',
        address: '',
        notes: ''
      };
    },
    closeOfflineModel() {
      this.showOfflineOrderForm = false;
    }
  }
}
</script>
